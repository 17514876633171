import styled from "styled-components";

export const NavbarWrapper = styled.nav`
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
`;

export const NavbarContainer = styled.div`
    padding: .75rem 1.25rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(226 232 240);
    & > img {
        width: 50px;
    }
`;

export const NavbarMarquee = styled.div`
    background-color: #333;
    padding: 6px 8px;
    color: #fff;
`;

export const MarqueeContent = styled.div`
    margin-right: 8px;
    & > span {
        color: #fff;
        font-size: 14px;

        & > a {
            color: inherit;
            font-weight: 600;
            font-size: 14px;
        }
    }
`;