import React, { useEffect, useState } from "react";
import { TfiLocationPin } from 'react-icons/tfi';
import Select from 'react-select';
import profile from '../../assets/image/profile.png';
import { selectStyles } from "../../global";
import { isGMaps, paginate } from "../../utils";
import Pagination from "../pagination";
import { FilterWrapper, ForumGrid, ForumSocialMedia, ForumWrapper, ImageWrapper, SocialMediaWrapper, StoreCard, StoreCardDetail } from "../stores/style";
import { foodData } from "./data";

export const Food = () => {
    const category = [
        {
            value: "Rumah Makan",
            label: "🧑🏻‍🍳 Rumah Makan"
        },
        {
            value: "Cafe",
            label: "☕️ Cafe"
        }
    ]

    const [selected, setSelected] = useState(category[0].value);
    const [filtered, setFiltered] = useState([]);
    const [paginated, setPaginated] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 5;


    useEffect(() => {
        fetchFiltered();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchFiltered = () => {
        const filteredData = foodData.filter(item => item.type.toLowerCase() === selected.toLowerCase());
        const paginated = paginate(filteredData, currentPage, pageSize);
        setPaginated(paginated);
        setFiltered(filteredData);
    }

    const onCategoryClicked = (value) => {
        if (value === null) {
            const filteredData = foodData.filter(item => item.type === category[0].value);
            const paginated = paginate(foodData, 0, pageSize);
            setCurrentPage(0);
            setPaginated(paginated);
            setFiltered(filteredData);
            setSelected(category[0].value);
            return
        }
        const filteredData = foodData.filter(item => item.type === value.value);
        const paginated = paginate(filteredData, 0, pageSize);
        setCurrentPage(0);
        setPaginated(paginated);
        setFiltered(filteredData);
        setSelected(value.value);
    }


    return (
        <ForumWrapper>
            <FilterWrapper>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={category[0]}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="so_number"
                    options={category}
                    onChange={onCategoryClicked}
                    styles={selectStyles}
                />
            </FilterWrapper>
            <ForumGrid>
                {paginated.length === 0 ? <h1>Oops! Belum ada nih. Coba cek lagi besok ya!</h1> :
                    paginated.map((item, index) => <StoreCard key={index}>
                        <ImageWrapper>
                            <img src={profile} alt="profile" />
                        </ImageWrapper>
                        <StoreCardDetail>
                            <h1>{item['name']}</h1>
                            <p>{item['type']} • {item['ambience']}</p>
                            <p>{item['budget']}</p>
                            <ForumSocialMedia>
                                {isGMaps(item['gmaps']) ? <SocialMediaWrapper href={item['gmaps']}>
                                    <TfiLocationPin size={18} />
                                </SocialMediaWrapper> : null}
                            </ForumSocialMedia>
                        </StoreCardDetail>
                    </StoreCard>)}
            </ForumGrid>
            <div style={{ marginTop: "1rem" }}>
                <Pagination
                    itemsCount={filtered.length}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                        setCurrentPage(page);
                        const paginated = paginate(filtered, page, pageSize)
                        setPaginated(paginated);
                    }}
                    currentPage={currentPage}
                />
            </div>
        </ForumWrapper>
    )
}