import React, { useState } from "react";
import { Business } from "../business";
import { ComingSoon } from "../comingsoon";
import { Food } from "../food";
import { Footer } from "../footer";
import { Category, CategoryFlex } from "../stores/style";
import { BodyHeader, BodyWrapper } from "./style";

const menus = [
    {
        label: "🧳 Usaha",
        value: "Usaha"
    },

    {
        label: "🍙 Makanan",
        value: "FnB"
    }
]

export const Body = () => {
    const [menu, setMenu] = useState(menus[0].value);

    return (
        <BodyWrapper>
            <BodyHeader data-aos="fade-up">
                <h1>Forum Warga <br /> Park Serpong</h1>
                <p>Mau kenal sama tetangga satu cluster atau pengen tau siapa aja yang jadi penghuni Park Serpong? Cek aja deh dimari.</p>
            </BodyHeader>
            <CategoryFlex data-aos="fade-up">
                {menus.map((item, index) => <Category key={index} color={menu === item['value'] ? "#fff" : "#333"} bgColor={menu === item['value'] ? "#333" : "#f7f7f7"} border="1px solid #e9e9e9" onClick={() => setMenu(item.value)}>
                    {item['label']}
                </Category>)}
            </CategoryFlex>
            {/* <BodyHeaderFlex>
                <BodyHeaderButton bgColor={"rgb(17 24 39)"} color="#fff" border="1px solid rgb(17 24 39)" href="https://wa.me/+6281905388170?text=Gan, saya mau edit alamat saya dong.">
                    <FiEdit style={{ color: "white", fill: "white" }} />
                    <span>Request Edit Alamat</span>
                </BodyHeaderButton>
            </BodyHeaderFlex> */}
            {menu === "Usaha" ? <Business /> : menu === "FnB" ? <Food /> : null}
            <ComingSoon />
            <Footer />
        </BodyWrapper>
    )
}