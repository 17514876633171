import styled from "styled-components";

export const FooterContainer = styled.footer`
    /* border-block-start: 1px solid rgba(0,0,0,.06); */
    padding: 1rem 0;
    background-color: #fff;
`;

export const FooterFlex = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
    }
`;

export const FooterLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    & > img {
        width: 50px;
    }
    & > p {
        color: rgba(0,0,0,.8);
        font-size: 13px;
    }

    & > span {
        color: rgba(0,0,0,.8);
        font-size: 15px;
        margin-bottom: 12px;
        max-width: 350px;
        line-height: 1.5;
    }

    & > h1 {
        font-weight: 500;
        font-size: 21px;
    }
`;

export const FooterSocial = styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 1rem;

    & > a {
        color: #000;
    }

    @media screen and (min-width: 1024px) {
        margin-bottom: 0;
    }
`;