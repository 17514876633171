export const foodData = [
    {
        "name": "Bajawa Coffee",
        "type": "Cafe",
        "ambience": "Lumayan Bagus/Nyaman",
        "budget": "Under 100",
        "notes": "Halal",
        "Rasa": "Enak",
        "gmaps": "https://maps.app.goo.gl/VsFE4ctSUAy7nws5A"
    },
    {
        "name": "Marenza",
        "type": "Cafe",
        "ambience": "Bagus/Nyaman",
        "budget": "Under 50",
        "notes": "Fasilitas Wifi Dan Barbeque",
        "Rasa": "Enak",
        "gmaps": "Link Gmaps Apa Ya?"
    },
    {
        "name": "Ayam Panggang Mbah Mo",
        "type": "Rumah Makan",
        "ambience": "Bagus/Nyaman",
        "budget": "Under 100",
        "notes": "Menu Wajib Beli Pilih Paket 1/2 Ekor Panggang Yg Gurih Parkiran Luas Hanya Masih Tanah Batu Kecil...Lebih Ke Masakan Jawa",
        "Rasa": "Enak",
        "gmaps": "https://g.co/kgs/14w9bHd"
    },
    {
        "name": "Kopi Tuku",
        "type": "Cafe",
        "ambience": "Bagus/Nyaman",
        "budget": "Under 50",
        "notes": "Gak Ada Sih Enak Intinya",
        "Rasa": "Enak",
        "gmaps": "Check Di Google Aja"
    },
    {
        "name": "Nirmala",
        "type": "Rumah Makan",
        "ambience": "Lumayan Bagus/Nyaman",
        "budget": "Under 100",
        "notes": "Pilihannya Cukup Banyak Dan Enak Enak, Harganya Juga Relatif Murah",
        "Rasa": "Enak",
        "gmaps": "https://maps.app.goo.gl/CZWeY9BGgd5fLfSw5"
    },
    {
        "name": "Ayam Goreng Mba Ni",
        "type": "Rumah Makan",
        "ambience": "Bagus/Nyaman",
        "budget": "Under 50",
        "notes": "",
        "Rasa": "Enak",
        "gmaps": "Cek Google Mala"
    },
    {
        "name": "Bakso Gepeng Mas Didi Gs",
        "type": "Rumah Makan",
        "ambience": "Lumayan Bagus/Nyaman",
        "budget": "Under 50",
        "notes": "",
        "Rasa": "Enak",
        "gmaps": "Jalan Ruko Opal, Kelapa Sawit Raya No. Bd 12/10, Pakulonan Barat, Kelapa Dua, Kab. Tangerang Banten"
    },
    {
        "name": "Bakmi Ayam Bangka Dua Sahabat",
        "type": "Rumah Makan",
        "ambience": "B Aja",
        "budget": "Under 50",
        "notes": "",
        "Rasa": "Enak",
        "gmaps": "https://maps.app.goo.gl/17Nkjw9x3V9sRYiu8"
    }
]
