import React, { useEffect, useState } from "react";
import { BsGlobe } from 'react-icons/bs';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { TbBrandShopee } from 'react-icons/tb';
import Select from 'react-select';
import profile from '../../assets/image/profile.webp';
import tokopedia from '../../assets/image/tokopedia.png';
import { selectStyles } from "../../global";
import { filterBusiness, paginate } from "../../utils";
import { BodyHeaderButton } from "../body/style";
import Pagination from "../pagination";
import { FilterWrapper, ForumGrid, ForumSocialMedia, ForumWrapper, ImageWrapper, SocialMediaWrapper, StoreCard, StoreCardDetail } from "../stores/style";
import { businessData } from "./data";


export const Business = () => {
    const category = [
        {
            value: "Interior/Arsitektur",
            label: "🏯 Interior/Arsitektur"
        },
        {
            value: "Food & Beverage",
            label: "🍝 Makanan & Minuman"
        },
        {
            value: "Pembuatan Web & Aplikasi",
            label: "💻 Pembuatan Web & Aplikasi"
        },
        {
            value: "Properti",
            label: "⛩️ Properti"
        },
        {
            value: "Kesehatan",
            label: "⚕️ Kesehatan"
        },
        {
            value: "Toko/Usaha Sendiri",
            label: "🏬 Toko/Usaha Sendiri"
        },
        {
            value: "Handcraft",
            label: "🧶 Handcraft"
        },
        {
            value: "Kendaraan",
            label: "🚙 Kendaraan"
        }
    ]

    const [selected, setSelected] = useState(category[0].value);
    const [filtered, setFiltered] = useState([]);
    const [paginated, setPaginated] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 5;


    useEffect(() => {
        fetchFiltered();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchFiltered = () => {
        const filteredData = businessData.filter(item => item.type.toLowerCase() === selected.toLowerCase());
        // filteredData.sort((a, b) => {
        //     let fa = a.name.toLowerCase(),
        //         fb = b.name.toLowerCase();

        //     if (fa < fb) {
        //         return -1;
        //     }
        //     if (fa > fb) {
        //         return 1;
        //     }
        //     return 0;
        // });
        const paginated = paginate(filteredData, currentPage, pageSize);
        setPaginated(paginated);
        setFiltered(filteredData);
    }

    const onCategoryClicked = (value) => {
        if (value === null) {
            const filteredData = businessData.filter(item => item.type === category[0].value);
            const paginated = paginate(businessData, 0, pageSize);
            setCurrentPage(0);
            setPaginated(paginated);
            setFiltered(filteredData);
            setSelected(category[0].value);
            return
        }
        const filteredData = businessData.filter(item => item.type === value.value);
        const paginated = paginate(filteredData, 0, pageSize);
        setCurrentPage(0);
        setPaginated(paginated);
        setFiltered(filteredData);
        setSelected(value.value);
    }


    return (
        <ForumWrapper>
            <FilterWrapper>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={category[0]}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="so_number"
                    options={category}
                    onChange={onCategoryClicked}
                    styles={selectStyles}
                />
                <BodyHeaderButton bgColor={"rgb(17 24 39)"} color="#fff" border="1px solid rgb(17 24 39)" href="https://wa.me/+6281905388170?text=Gan, saya mau tambah usaha saya dong.">
                    <FiEdit style={{ color: "white", fill: "white" }} />
                    <span>Tambah</span>
                </BodyHeaderButton>
            </FilterWrapper>
            <ForumGrid>
                {paginated.length === 0 ? <h1>Oops! Belum ada nih. Coba cek lagi besok ya!</h1> :
                    paginated.map((item, index) => <StoreCard key={index}>
                        <ImageWrapper>
                            {item['image_url'] === "" ? (<img src={profile} alt="profile" />) : <img src={item['image_url']} alt="profile" />}
                        </ImageWrapper>
                        <StoreCardDetail>
                            <h1>{item['business_name']}</h1>
                            <p>{item['type']} • {item['business_type']}</p>
                            <p>{item['period'].toLowerCase().includes('tahun') ? item['period'] : (`${item['period']} tahun`)}</p>
                            <ForumSocialMedia>
                                {filterBusiness(item['link']) && item['link'] !== '' ? <SocialMediaWrapper href={item['link']}>
                                    <BsGlobe size={17} />
                                </SocialMediaWrapper> : null}
                                {item['link'].includes("shopee") ? <SocialMediaWrapper href={item['link']}>
                                    <TbBrandShopee size={22} />
                                </SocialMediaWrapper> : null}
                                {item['link'].includes("tokopedia") ? <SocialMediaWrapper href={item['link']}>
                                    <img src={tokopedia} alt="tokopedia" />
                                </SocialMediaWrapper> : null}
                                {item['instagram_url'] !== undefined ? <SocialMediaWrapper href={item['instagram_url']}>
                                    <FaInstagram size={20} />
                                </SocialMediaWrapper> : null}
                                {item['link'].includes("instagram") ? <SocialMediaWrapper href={item['link']}>
                                    <FaInstagram size={20} />
                                </SocialMediaWrapper> : null}
                                {item['shopee_url'] !== undefined ? <SocialMediaWrapper href={item['shopee_url']}>
                                    <TbBrandShopee size={22} />
                                </SocialMediaWrapper> : null}
                                {item['whatsapp_url'] !== undefined ? <SocialMediaWrapper href={item['whatsapp_url']}>
                                    <FaWhatsapp size={20} />
                                </SocialMediaWrapper> : item['phone'] !== "" ? <SocialMediaWrapper href={`https://wa.me/${item['phone']}?text=Halo saya tertarik dengan jasanya`}>
                                    <FaWhatsapp size={20} />
                                </SocialMediaWrapper> : null}
                            </ForumSocialMedia>
                        </StoreCardDetail>
                    </StoreCard>)}
            </ForumGrid>
            <div style={{ marginTop: "1rem" }}>
                <Pagination
                    itemsCount={filtered.length}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                        setCurrentPage(page);
                        const paginated = paginate(filtered, page, pageSize)
                        setPaginated(paginated);
                    }}
                    currentPage={currentPage}
                />
            </div>
        </ForumWrapper>
    )
}