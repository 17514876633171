import _ from 'lodash';

export function paginate(items, pageNumber, pageSize) {
    const startIndex = (pageNumber) * pageSize;
    //_.slice(items, startIndex);
    return _(items).slice(startIndex).take(pageSize).value();
}

export const filterBusiness = (link) => {
    return !(link.includes("shopee") || link.includes("tokopedia") || link.includes("instagram"))
}

export const isGMaps = (link) => {
    return link.includes("maps.app") || link.includes("g.co") || link.includes("Jalan")
}