import { createGlobalStyle } from "styled-components";
import AlteRegularWoff from '../assets/fonts/alte/AlteHaasGrotesk.woff';
import AlteRegularWoff2 from '../assets/fonts/alte/AlteHaasGrotesk.woff2';
import AlteBoldWoff from '../assets/fonts/alte/AlteHaasGrotesk_Bold.woff';
import AlteBoldWoff2 from '../assets/fonts/alte/AlteHaasGrotesk_Bold.woff2';

export const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'Alte';
      src: url('${AlteRegularWoff2}') format('woff2'),
          url('${AlteRegularWoff}') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Alte';
      src: url('${AlteBoldWoff2}') format('woff2'),
          url('${AlteBoldWoff}') format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }
  
    * {
        font-family: 'Reddit Sans', sans-serif;
        margin: 0;
        padding: 0;
        color: #333;
    }

    h1, h2, h3, h4 {
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin: 0;
        padding: 0;
        letter-spacing: -.025em;
        line-height: 2.5rem;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }


    .page-link {
        font-size: 14px;
        padding: .375rem .75rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        border: 1px solid #dee2e6;
        display: block;
        color: rgb(51, 51, 51);
    }
    .page-item.active .page-link {
        background-color: rgb(51, 51, 51);
        border-color: rgb(51, 51, 51);
        color: #fff;
    }

    .page-item:not(:first-child) .page-link {
        margin-left: -1px;
    }

    .active>.page-link {
        z-index: 0;
    }
    
    .pagination {
        list-style: none;
        display: flex;
        z-index: 999;
    }

    .page-link:hover {
        background-color: #f8f9fa;
        border-color: #dee2e6;
    }

    .page-item:first-child .page-link {
        border-bottom-left-radius: 0.375rem;
        border-top-left-radius: 0.375rem;
    }
    .page-item:last-child .page-link {
        border-bottom-right-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
    }

    .disabled > .page-link {
        background-color: #e9ecef;
        border: 1px solid #dee2e6;
        color: rgba(33,37,41,.75);
    }
`;


export const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        background: "#fff",
        borderColor: "#ddd",
        minHeight: "40px",
        height: "40px",
        fontSize: "13px",
        width: "210px",
        boxShadow: state.isFocused ? null : null,
    }),
    option: (provided) => ({
        ...provided,
        fontSize: "13px",
    })
};