export const businessData = [
    {
        "business_name": "ig : @studio_bytepa",
        "business_type": "Interior Design ; Renovasi Sipil ; Interior Styling",
        "period": "3",
        "link": "https://www.instagram.com/studio_bytepa?igsh=MXM2NzJvOWsyZ2F5aA%3D%3D&utm_source=qr",
        "phone": "6285332345421",
        "image_url": "",
        "type": "Interior/Arsitektur"
    },
    {
        "business_name": "karyajemarica",
        "business_type": "Amigurumi (boneka rajut, gantungan kunci rajut)",
        "period": "2",
        "link": "shopee.co.id/karyajemarica",
        "phone": "628119412117",
        "image_url": "",
        "type": "Handcraft"
    },
    {
        "business_name": "Mitsubishi",
        "business_type": "Mobil passanger dan niaga",
        "period": "4",
        "link": "Jl. Prof. Dr. Latumenten no. 5",
        "phone": "6281807387277",
        "image_url": "",
        "type": "Kendaraan"
    },
    {
        "business_name": "Infinite Pro",
        "business_type": "Jasa Jual - Beli - Sewa Semua Properti",
        "period": "13 Tahun di industri Properti",
        "link": "https://infinitepro.co.id/",
        "phone": "6282122290057",
        "image_url": "",
        "type": "Properti"
    },
    {
        "business_name": "Fnb",
        "business_type": "Rice bowl, Lauk matang dan Frozen Snack ",
        "period": "6 tahun",
        "link": "https://www.instagram.com/pawon.oemahan?igsh=b2JiM2tqNDh6bXc3",
        "phone": "6288214305139",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720762989/parkserpong/business/pawon_mh5yga.jpg",
        "type": "Food & Beverage"
    },
    {
        "business_name": "Pempek Loliloli",
        "business_type": "Pempek Palembang ",
        "period": "4 tahun",
        "link": "https://tokopedia.link/3SsRMa2RYKb",
        "shopee_url": "https://shopee.co.id/pempekloliloli",
        "instagram_url": "https://www.instagram.com/pempekloliloli?igsh=MTZlYmM5aDFnNWx6",
        "phone": "6281280212225",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720761001/parkserpong/business/pempek_kbqmu5.jpg",
        "type": "Food & Beverage"
    },
    {
        "business_name": "ASURANSI  PRUDENTIAL ",
        "business_type": "ASURANSI kesehatan , pendidikan ,sakit kritis dan Warisan ",
        "period": "8 tahun ",
        "link": "",
        "phone": "6287812552217",
        "image_url": "",
        "type": "Kesehatan"
    },
    {
        "business_name": "The Local Design Studio",
        "business_type": "Jasa Interior Design, Arsitek, Styling, dan Dekorasi",
        "period": "5 tahun",
        "link": "https://thelocaldstudio.com",
        "instagram_url": "https://instagram.com/thelocaldstudio",
        "phone": "628118383313",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720761834/parkserpong/business/locald_wwxphr.png",
        "type": "Interior/Arsitektur"
    },
    {
        "business_name": "WR Pro",
        "business_type": "Waterproofing",
        "period": "2",
        "link": "https://tokopedia.link/Tqz702YO6Kb",
        "phone": "6285219385420",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720761974/parkserpong/business/wrpro_xxenqz.jpg",
        "type": "Toko/Usaha Sendiri"
    },
    {
        "business_name": "Jamu Aramu",
        "business_type": "JAMU ARAMU dan JUS BAWANG",
        "period": "1 tahun",
        "link": "https://shopee.co.id/daily_cartt",
        "phone": "6281288028715",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720760998/parkserpong/business/aramu_fybyq7.jpg",
        "type": "Food & Beverage"
    },
    {
        "business_name": "Jasa Akunting dan Pajak",
        "business_type": "Jasa Pembukuan dan Perpajakan Pribadi dan Perusahaan",
        "period": "7",
        "link": "Jakarta - Bogor",
        "phone": "6281377883011",
        "image_url": "",
        "type": "Toko/Usaha Sendiri"
    },
    {
        "business_name": "Pempek Keela",
        "business_type": "Pempek",
        "period": "3 tahun",
        "link": "Ciputat",
        "phone": "628881851573",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720761081/parkserpong/business/keela_jcopbt.png",
        "type": "Food & Beverage"
    },
    {
        "business_name": "Asuransi ",
        "business_type": "Asuransi Allianz ",
        "period": "3 tahun",
        "link": "",
        "phone": "6282312373383",
        "image_url": "",
        "type": "Kesehatan"
    },
    {
        "business_name": "Pangsit Goreng ENAK",
        "business_type": "Pangsit Goreng",
        "period": "3 bulan",
        "link": "https://www.instagram.com/pangsitgoreng_enak/",
        "phone": "6285921449157",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720761491/parkserpong/business/pangsit_peckvy.jpg",
        "type": "Food & Beverage"
    },
    {
        "business_name": "Glamoure official",
        "business_type": "pakaian/fashion",
        "period": "-",
        "link": "https://shopee.co.id/glamoureofficial",
        "phone": "6282111096200",
        "image_url": "https://down-ws-id.img.susercontent.com/id-11134233-7r991-lx3qg8quxp8db1_tn.webp",
        "type": "Toko/Usaha Sendiri"
    },
    {
        "business_name": "Karyawan & Pedagang Ayam",
        "business_type": "Ayam kuning dan kalasan, sembako, catering (by req)",
        "period": "3 tahun",
        "link": "sembako & ayam kuning kalasan (pasar lama tangerang, toko ilian) catering (sektor 1B)",
        "phone": "6285697573208",
        "image_url": "",
        "type": "Food & Beverage"
    },
    {
        "business_name": "Toko Aksesoris & Device HP",
        "business_type": "yess owner langsung suami saya",
        "period": "6",
        "link": "",
        "phone": "6285772710005",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720760993/parkserpong/business/aksesoris_hape_riycab.jpg",
        "type": "Toko/Usaha Sendiri"
    },
    {
        "business_name": "Tissue by Ren",
        "business_type": "Tissue, plastik sampah, plastik wrap, saringan sink, lem lalat, sabun cuci piring",
        "period": "2",
        "link": "Citra Raya cluster east portofino ",
        "phone": "628176587016",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720761000/parkserpong/business/tissue_n74qjr.jpg",
        "type": "Toko/Usaha Sendiri"
    },
    {
        "business_name": "Pempek ",
        "business_type": "Pempek",
        "period": "4th",
        "link": "https://tokopedia.link/v5ReCRAB8Kb",
        "phone": "6281280212225",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720761031/parkserpong/business/loliloli_ir2fw2.jpg",
        "type": "Food & Beverage"
    },
    {
        "business_name": "Needev",
        "business_type": "Baju dan skincare import (tp yg skincare jastipan ya hga bisa order banyak dan sering2)",
        "period": "8 tahun",
        "link": "https://s.shopee.co.id/4AfU2XxDSU",
        "phone": "6289699320501",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720760992/parkserpong/business/needev_jhh5kc.jpg",
        "type": "Toko/Usaha Sendiri"
    },
    {
        "business_name": "Sales mobil mitsubishi",
        "business_type": "Mobil baru XPANDER XFORCE PAJERO",
        "period": "1",
        "link": "Gaada",
        "phone": "6281213355647",
        "image_url": "",
        "type": "Kendaraan"
    },
    {
        "business_name": "PT Unimax Power",
        "business_type": "Suplier & General Trading",
        "period": "15 Tahun",
        "link": "JAKARTA",
        "phone": "6281280212225",
        "image_url": "",
        "type": "Toko/Usaha Sendiri"
    },
    {
        "business_name": "Jastip batam",
        "business_type": "Jastip snack batam",
        "period": "5",
        "link": "",
        "phone": "6281809092994",
        "image_url": "",
        "type": "Food & Beverage"
    },
    {
        "business_name": "Jas Hujan Daiya",
        "business_type": "Jas Hujan",
        "period": "15 tahun",
        "link": "",
        "phone": "6281213258000",
        "image_url": "",
        "type": "Toko/Usaha Sendiri"
    },
    {
        "business_name": "karyajemarica",
        "business_type": "Amigurumi (boneka rajut, gantungan kunci rajut)",
        "period": "2",
        "link": "https://shopee.co.id/karyajemarica",
        "phone": "628119412117",
        "image_url": "https://down-ws-id.img.susercontent.com/id-11134216-7r98p-lwy9b7r5znr130_tn.webp",
        "type": "Toko/Usaha Sendiri"
    },
    {
        "business_name": "Sugari",
        "business_type": "konsultan pertanian",
        "period": "lebih dari 10 tahun",
        "link": "ga ada",
        "phone": "6281218155298",
        "image_url": "",
        "type": "Toko/Usaha Sendiri"
    },
    {
        "business_name": "Kang Satpol",
        "business_type": "Kang sayur",
        "period": "15 tahun",
        "link": "Sayur mayur",
        "phone": "6281312131415",
        "image_url": "",
        "type": "Food & Beverage"
    },
    {
        "business_name": "Bumi Craft",
        "business_type": "handmade boneka, hampers dll",
        "period": "2 tahun",
        "link": "https://tokopedia.link/8gZnlZdctAb",
        "shopee_url": "https://shopee.co.id/bumicraft",
        "whatsapp_url": "http://wa.me/6281297593806",
        "phone": "6285219385420",
        "image_url": "",
        "type": "Handcraft"
    },
    {
        "business_name": "Apotek",
        "business_type": "Obat dan Alat Kesehatan",
        "period": "3 tahun",
        "link": "",
        "phone": "6282129795356",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720761756/parkserpong/business/apotek_gmudp6.jpg",
        "type": "Kesehatan"
    },
    {
        "business_name": "Arklify Studio",
        "business_type": "Social Media | UI/UX Design | Software Development | Bantu UMKM",
        "period": "4 tahun",
        "link": "https://arklify.com",
        "instagram_url": "https://instagram.com/arklify",
        "phone": "6281905388170",
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1720760992/parkserpong/business/arklify_vjkvfd.png",
        "type": "Pembuatan Web & Aplikasi"
    },
]