import React from "react";
import Marquee from "react-fast-marquee";
import logo from '../../assets/image/logo.png';
import { MarqueeContent, NavbarContainer, NavbarMarquee, NavbarWrapper } from "./style";

export const Navbar = () => {
    return (<NavbarWrapper>
        <NavbarMarquee>
            <Marquee autoFill speed={60}>
                <MarqueeContent>
                    <span>Yang mau di promote usahanya yuk mari hubungi mimin <a href="https://wa.me/6281905388170?text=Halo min, promote in dong">di sini</a> ✨</span>
                </MarqueeContent>
                <MarqueeContent>
                    <span>Penduduk Cityzen Hills sabar ya, lagi dibabat pohonnya. 🪓🌲</span>
                </MarqueeContent>
            </Marquee>
        </NavbarMarquee>
        <NavbarContainer>
            <img src={logo} alt="logo" />
        </NavbarContainer>
    </NavbarWrapper>)
}