import styled from "styled-components";

export const ForumWrapper = styled.div`
    margin-top: 2rem;
    padding-bottom: 2rem;
`;

export const ForumGrid = styled.div`
    display: grid;
    gap: 1rem;

    & > h1 {
        font-size: 21px;
        line-height: 1.3em;
    }
`;

export const ImageWrapper = styled.div`
    background-color: #eee;
    width: 82px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    border-radius: 8px;

    & > img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 8px;
    }
`;

export const ForumCard = styled.div`
    padding: 2rem 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;

    & > span {
        color: rgb(107 114 128);
        font-size: 14px;
    }

    & > h1 {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.75rem;
        margin-top: 4px;
    }

    & > p {
        color: rgb(107 114 128);
        margin: .5rem 0;
    }
`;

export const FilterWrapper = styled.div`
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    & > div {
        z-index: 0;
    }
`;

export const CategoryWrapper = styled.div`
    overflow: scroll;
    margin: 1rem 0 2rem;

    &::-webkit-scrollbar{ 
        display: none;
    }
`;

export const CategoryFlex = styled.div`
    display: flex;
    gap: 8px;
`;

export const Category = styled.span`
    padding: 4px 12px;
    flex-shrink: 0;
    color: ${({ color }) => color};
    font-size: 14px;
    border-radius: 8px;
    background-color: ${({ bgColor }) => bgColor};
    border: ${({ border }) => border};
    cursor: pointer;
    font-weight: 600;
`;

export const ForumSocialMedia = styled.div`
    display: flex;
    margin-top: 8px;
    align-items: center;
    gap: 10px;
`;

export const SocialMediaWrapper = styled.a`
    border-radius: 10px;
    display: flex;
    gap: 4px;
    align-items: center;
    text-decoration: none;

    & > img {
        width: 18px;
    }
`;

export const StoreCard = styled.div`
    display: flex;
    gap: 1rem;
`;

export const StoreCardDetail = styled.div`
    display: flex;
    flex-direction: column;

    & > span {
        color: rgb(107 114 128);
        font-size: 14px;
    }

    & > h1 {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.75rem;
        margin-top: 4px;
    }

    & > p {
        font-size: 14px;
        color: rgb(107 114 128);
        margin: .15rem 0;
        line-height: 1.2rem;
    }
`;