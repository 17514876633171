import React from "react";
import logo from '../../assets/image/logo.png';
import { FooterContainer, FooterFlex, FooterLeft } from "./style";

export const Footer = () => {
    return (
        <FooterContainer>
            <FooterFlex>
                <FooterLeft>
                    <img src={logo} alt="logo" />
                    <span>Menghubungkan Warga Park Serpong. Curhat, share info, dan update bareng komunitas. #WargaParkSerpong</span>
                    <p>© 2024 WargaParkSerpong - made with 🫶🏻 by <a href="https://arklify.com" target="_blank" rel="noreferrer">Warga Cityzen Hills</a></p>
                </FooterLeft>
            </FooterFlex>
        </FooterContainer >
    )
}