import styled from "styled-components";

export const AppsWrapper = styled.div`
  width: 100%;
  height: 100vh;
  margin: auto;
  max-width: 500px;
`;
export const BodyWrapper = styled.div`
    padding: 2rem 1.5rem;
    background: linear-gradient(180deg,hsla(0,0%,100%,0),#fff 300px),0 0 /20px 20px radial-gradient(#d1d1d1 1px,transparent 0),10px 10px /20px 20px radial-gradient(#d1d1d1 1px,transparent 0);
`;

export const BodyHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    & > h1 {
        font-size: 2.25rem;
        text-align: center;
        font-weight: 800;
    }

    & > p {
        font-size: 1.125rem;
        line-height: 1.5rem;
        text-align: center;
        letter-spacing: -.03em;
        margin-top: 1rem;
        color: rgb(75 85 99);
    }
`;

export const BodyHeaderFlex = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 1rem 0 1.5rem;
`;

export const BodyHeaderButton = styled.a`
    text-decoration: none;
    background-color: ${({ bgColor }) => bgColor};
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    border: ${({ border }) => border};

    & > span {
        color: ${({ color }) => color};
        font-weight: 500;
        font-size: 13px;
    }
`;
