import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import { Home } from './page/home';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  }
]);

const App = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  })
  return (
    <RouterProvider router={router} />
  );
}

export default App;
