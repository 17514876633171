import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";
import { Body } from "../../component/body";
import { AppsWrapper } from "../../component/body/style";
import { Navbar } from "../../component/navbar";

export const Home = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Homepage" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <AppsWrapper>
            <Navbar />
            <Body />
        </AppsWrapper>
    )
}