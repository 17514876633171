import React from "react";
import { Category, CategoryFlex } from "../stores/style";
import { SectionContainer, SectionWrapper } from "./style";

export const ComingSoon = () => {
    const category = ["💼 Lowongan Pekerjaan", "⛱️ Tempat Nongkrong", "📚 Sekolah", "🩺 Dokter", "💈 Barber", "🛒 Pasar", "💬 Forum", "📣 Laporan/Keluhan"]
    return (
        <SectionWrapper data-aos="fade-up" style={{ padding: "2rem 0", borderTop: "1px dashed #ddd", borderBottom: "1px dashed #ddd" }}>
            <SectionContainer>
                <h1>Fitur lainnya coming soon 🚀</h1>
                <p>Mohon ditunggu ya nder, demi kemajuan Warga Park Serpong</p>
                <CategoryFlex style={{ flexWrap: "wrap", marginTop: "1rem" }}>
                    {
                        category.map((item, index) => <Category key={index} border="1px solid #e9e9e9">
                            {item}
                        </Category>)
                    }
                </CategoryFlex>
                <p style={{ marginTop: "12px", fontSize: "14px" }}>dan lainnya ...</p>
            </SectionContainer>
        </SectionWrapper>
    )
}